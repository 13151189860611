import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Icon from 'react-icons-kit';
import { check } from 'react-icons-kit/feather/check';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import Fade from 'react-reveal/Fade';
//import TiltShape from '../TiltShape';
import Parent from './Parent'
//import TextHighlight from './TextHighlite'
//import Vertical from './TextTransform'

import { BannerWrapper, VideoModal, VideoModal2,
  PlayButton, Features,
  VideoWrapper } from './bannerSection.style';


  import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';
  import { play } from 'react-icons-kit/entypo/play';
  // close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);


  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://paycare.com/crypto/#/createWallet"
      frameBorder="0"
    />
  </VideoWrapper>
);

  // modal handler
  const handleVideoModal2 = () => {   
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
      },
      component: ModalContent2,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

const ModalContent2 = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://paycare.com/crypto/#/exchange/btc-to-eth"
      frameBorder="0"
    />
  </VideoWrapper>
);

const BannerSection = () => {
  const Data = useStaticQuery(graphql`
    query {
      sassMinimalJson {
        BANNER_DATA {
          title
          text
          image {
            publicURL
          }
          btnLabel
          btnLink
          offerText
        }
      }
    }
  `);
  return (
    <BannerWrapper id="banner_section">

      {Data.sassMinimalJson.BANNER_DATA.map((banner, index) => (
        <Container key={`banner-${index}`}>
          <Heading content={banner.title} as="h3" />
		         <div className="task2" >
      {/* Task Two and Three import from E:\src\containers\App1\BannerSection\TextHighlite.js  */}
    
       {/* E:\src\containers\App1\BannerSection\TextTransform.js */}


       </div>
          <Text content={banner.text} />
      
          <Link to={banner.btnLink}>
          <Button title={banner.btnLabel} onClick={handleVideoModal} />
          </Link>
       
	     
	             <Fade bottom>
              <VideoModal2>
         

            
              </VideoModal2>
            </Fade>

	               <Features>
              <li>
                <Icon size={20} icon={check} /> Trade with escrow risk-free Protection
              </li>
              <li>
                <Icon size={20} icon={check} /> Smart contract protocol
              </li>
              <li>
                <Icon size={20} icon={check} /> Global payment system
              </li>
            </Features>

            

            <Parent />



        </Container>
      ))}
      ;

    </BannerWrapper>
  );
};

export default BannerSection;
